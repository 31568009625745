import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bc4dfea6 = () => interopDefault(import('../pages/about-program.vue' /* webpackChunkName: "pages/about-program" */))
const _749c8bf6 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _697699f8 = () => interopDefault(import('../pages/artists/index.vue' /* webpackChunkName: "pages/artists/index" */))
const _500d2832 = () => interopDefault(import('../pages/business.vue' /* webpackChunkName: "pages/business" */))
const _e98853de = () => interopDefault(import('../pages/buy-paintings.vue' /* webpackChunkName: "pages/buy-paintings" */))
const _8c11698a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b02d4a8e = () => interopDefault(import('../pages/how-to-use.vue' /* webpackChunkName: "pages/how-to-use" */))
const _50d6e3d1 = () => interopDefault(import('../pages/join.vue' /* webpackChunkName: "pages/join" */))
const _66df0fa0 = () => interopDefault(import('../pages/join-arthall.vue' /* webpackChunkName: "pages/join-arthall" */))
const _f8c2235c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _65aa1006 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _06af63ca = () => interopDefault(import('../pages/paintings/index.vue' /* webpackChunkName: "pages/paintings/index" */))
const _12c97852 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _360410e0 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _57fdbcd0 = () => interopDefault(import('../pages/synergy.vue' /* webpackChunkName: "pages/synergy" */))
const _3389da3d = () => interopDefault(import('../pages/top-artists.vue' /* webpackChunkName: "pages/top-artists" */))
const _461716a0 = () => interopDefault(import('../pages/top-merch.vue' /* webpackChunkName: "pages/top-merch" */))
const _75e8fac0 = () => interopDefault(import('../pages/top-paintings.vue' /* webpackChunkName: "pages/top-paintings" */))
const _d82d4ac8 = () => interopDefault(import('../pages/merch/success.vue' /* webpackChunkName: "pages/merch/success" */))
const _70a319da = () => interopDefault(import('../pages/artists/_name/index.vue' /* webpackChunkName: "pages/artists/_name/index" */))
const _09cec153 = () => interopDefault(import('../pages/merch/_id.vue' /* webpackChunkName: "pages/merch/_id" */))
const _bbbe54b6 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _43c3ba1f = () => interopDefault(import('../pages/artists/_name/_paintingId.vue' /* webpackChunkName: "pages/artists/_name/_paintingId" */))
const _40ada1f8 = () => interopDefault(import('../pages/artists/_name' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-program",
    component: _bc4dfea6,
    meta: {},
    alias: ["/amp/about-program"],
    name: "about-program___ru"
  }, {
    path: "/about-us",
    component: _749c8bf6,
    meta: {},
    alias: ["/amp/about-us"],
    name: "about-us___ru"
  }, {
    path: "/artists",
    component: _697699f8,
    meta: {},
    alias: ["/amp/artists"],
    name: "artists___ru"
  }, {
    path: "/business",
    component: _500d2832,
    meta: {},
    alias: ["/amp/business"],
    name: "business___ru"
  }, {
    path: "/buy-paintings",
    component: _e98853de,
    meta: {},
    alias: ["/amp/buy-paintings"],
    name: "buy-paintings___ru"
  }, {
    path: "/en",
    component: _8c11698a,
    meta: {},
    alias: ["/amp/en"],
    name: "index___en"
  }, {
    path: "/how-to-use",
    component: _b02d4a8e,
    meta: {},
    alias: ["/amp/how-to-use"],
    name: "how-to-use___ru"
  }, {
    path: "/join",
    component: _50d6e3d1,
    meta: {},
    alias: ["/amp/join"],
    name: "join___ru"
  }, {
    path: "/join-arthall",
    component: _66df0fa0,
    meta: {},
    alias: ["/amp/join-arthall"],
    name: "join-arthall___ru"
  }, {
    path: "/login",
    component: _f8c2235c,
    meta: {},
    alias: ["/amp/login"],
    name: "login___ru"
  }, {
    path: "/news",
    component: _65aa1006,
    meta: {},
    alias: ["/amp/news"],
    name: "news___ru"
  }, {
    path: "/paintings",
    component: _06af63ca,
    meta: {},
    alias: ["/amp/paintings"],
    name: "paintings___ru"
  }, {
    path: "/profile",
    component: _12c97852,
    meta: {},
    alias: ["/amp/profile"],
    name: "profile___ru"
  }, {
    path: "/registration",
    component: _360410e0,
    meta: {},
    alias: ["/amp/registration"],
    name: "registration___ru"
  }, {
    path: "/synergy",
    component: _57fdbcd0,
    meta: {},
    alias: ["/amp/synergy"],
    name: "synergy___ru"
  }, {
    path: "/top-artists",
    component: _3389da3d,
    meta: {},
    alias: ["/amp/top-artists"],
    name: "top-artists___ru"
  }, {
    path: "/top-merch",
    component: _461716a0,
    meta: {},
    alias: ["/amp/top-merch"],
    name: "top-merch___ru"
  }, {
    path: "/top-paintings",
    component: _75e8fac0,
    meta: {},
    alias: ["/amp/top-paintings"],
    name: "top-paintings___ru"
  }, {
    path: "/en/about-program",
    component: _bc4dfea6,
    meta: {},
    alias: ["/amp/en/about-program"],
    name: "about-program___en"
  }, {
    path: "/en/about-us",
    component: _749c8bf6,
    meta: {},
    alias: ["/amp/en/about-us"],
    name: "about-us___en"
  }, {
    path: "/en/artists",
    component: _697699f8,
    meta: {},
    alias: ["/amp/en/artists"],
    name: "artists___en"
  }, {
    path: "/en/business",
    component: _500d2832,
    meta: {},
    alias: ["/amp/en/business"],
    name: "business___en"
  }, {
    path: "/en/buy-paintings",
    component: _e98853de,
    meta: {},
    alias: ["/amp/en/buy-paintings"],
    name: "buy-paintings___en"
  }, {
    path: "/en/how-to-use",
    component: _b02d4a8e,
    meta: {},
    alias: ["/amp/en/how-to-use"],
    name: "how-to-use___en"
  }, {
    path: "/en/join",
    component: _50d6e3d1,
    meta: {},
    alias: ["/amp/en/join"],
    name: "join___en"
  }, {
    path: "/en/join-arthall",
    component: _66df0fa0,
    meta: {},
    alias: ["/amp/en/join-arthall"],
    name: "join-arthall___en"
  }, {
    path: "/en/login",
    component: _f8c2235c,
    meta: {},
    alias: ["/amp/en/login"],
    name: "login___en"
  }, {
    path: "/en/news",
    component: _65aa1006,
    meta: {},
    alias: ["/amp/en/news"],
    name: "news___en"
  }, {
    path: "/en/paintings",
    component: _06af63ca,
    meta: {},
    alias: ["/amp/en/paintings"],
    name: "paintings___en"
  }, {
    path: "/en/profile",
    component: _12c97852,
    meta: {},
    alias: ["/amp/en/profile"],
    name: "profile___en"
  }, {
    path: "/en/registration",
    component: _360410e0,
    meta: {},
    alias: ["/amp/en/registration"],
    name: "registration___en"
  }, {
    path: "/en/synergy",
    component: _57fdbcd0,
    meta: {},
    alias: ["/amp/en/synergy"],
    name: "synergy___en"
  }, {
    path: "/en/top-artists",
    component: _3389da3d,
    meta: {},
    alias: ["/amp/en/top-artists"],
    name: "top-artists___en"
  }, {
    path: "/en/top-merch",
    component: _461716a0,
    meta: {},
    alias: ["/amp/en/top-merch"],
    name: "top-merch___en"
  }, {
    path: "/en/top-paintings",
    component: _75e8fac0,
    meta: {},
    alias: ["/amp/en/top-paintings"],
    name: "top-paintings___en"
  }, {
    path: "/merch/success",
    component: _d82d4ac8,
    meta: {},
    alias: ["/amp/merch/success"],
    name: "merch-success___ru"
  }, {
    path: "/en/merch/success",
    component: _d82d4ac8,
    meta: {},
    alias: ["/amp/en/merch/success"],
    name: "merch-success___en"
  }, {
    path: "/en/artists/:name",
    component: _70a319da,
    meta: {},
    alias: ["/amp/en/artists/:name"],
    name: "artists-name___en"
  }, {
    path: "/en/merch/:id?",
    component: _09cec153,
    meta: {},
    alias: ["/amp/en/merch/:id?"],
    name: "merch-id___en"
  }, {
    path: "/en/news/:id",
    component: _bbbe54b6,
    meta: {},
    alias: ["/amp/en/news/:id"],
    name: "news-id___en"
  }, {
    path: "/en/artists/:name/:paintingId",
    component: _43c3ba1f,
    meta: {},
    alias: ["/amp/en/artists/:name/:paintingId"],
    name: "artists-name-paintingId___en"
  }, {
    path: "/artists/:name",
    component: _70a319da,
    meta: {},
    alias: ["/amp/artists/:name"],
    name: "artists-name___ru"
  }, {
    path: "/merch/:id?",
    component: _09cec153,
    meta: {},
    alias: ["/amp/merch/:id?"],
    name: "merch-id___ru"
  }, {
    path: "/news/:id",
    component: _bbbe54b6,
    meta: {},
    alias: ["/amp/news/:id"],
    name: "news-id___ru"
  }, {
    path: "/artists/:name/paintings/:id",
    components: {
      default: _40ada1f8
    },
    meta: {},
    alias: ["/amp/artists/:name/paintings/:id"]
  }, {
    path: "/artists/:name/:paintingId",
    component: _43c3ba1f,
    meta: {},
    alias: ["/amp/artists/:name/:paintingId"],
    name: "artists-name-paintingId___ru"
  }, {
    path: "/",
    component: _8c11698a,
    meta: {},
    alias: ["/amp/"],
    name: "index___ru"
  }],

  parseQuery: function(query) {
      const queryString = query.replace(/&amp%3B/g, '&');
      return require('qs').parse(queryString);
    },
  stringifyQuery: function(query) {
      const result = require('qs').stringify(query);
      return result ? '?' + result : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
