import { render, staticRenderFns } from "./AmpHeader.vue?vue&type=template&id=4e5c46d3&"
import script from "./AmpHeader.vue?vue&type=script&lang=ts&"
export * from "./AmpHeader.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AmpMenu: require('/var/www/arthall/arthall.front/components/amp/layout/AmpMenu.vue').default})
