
import { defineComponent } from 'vue'
import LayoutMenu from './LayoutMenu.vue'
import SocialLinks from './SocialLinks.vue'

export default defineComponent({
  components: {
    LayoutMenu,
    SocialLinks
  },

  data () {
    return {
      title: 'Онлайн-галерея российских художников'
    }
  },
  head () {
    return {

    }
  }
})

