import Vue from 'vue'
import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

class EventBus extends Vue {}

/**
 * @param {Context} ctx - nuxt контекст
 * @param {Inject} inject - функция добавления плагина
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function (ctx: Context, inject: Inject) {
  inject('eventBus', new EventBus())
}
