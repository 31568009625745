
import { Component, Vue } from 'nuxt-property-decorator'
import PaintingRate from '~/components/index/PaintingRate.vue'
import FullScreen from '~/components/FullScreen.vue'
import SaleInfo from '~/components/sale/SaleInfo.vue'

@Component({
  components: {
    FullScreen,
    PaintingRate,
    SaleInfo
  }
})
export default class extends Vue {
  showPopup = false
  isShowBuyInfo = false
  img: string = ''
  rate: number = 0
  paintId: number = 0
  paintingInfo: any = {}
  // merchSaleAgree: number = 0
  userCurrentRate: number | null = null

  mounted () {
    this.$eventBus.$on('SHOW_FULLSCREEN', (data) => {
      this.rate = data.rate
      this.paintId = data.id
      this.img = data.original_file ? data.original_file : data.image
      // this.merchSaleAgree = data.merchSaleAgree
      this.showPopup = true
      this.userCurrentRate = data.user_current_rate
      this.paintingInfo = data
      this.isShowBuyInfo = data?.show_buy || false
    })
  }

  merchClick () {
    // this.outFullscreen()
    this.$router.push('/merch/' + this.paintId)
  }
}
