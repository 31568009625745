import Vue from 'vue'

Vue.filter('formatDate', function (value, time = false) {
  if (value) {
    if (!time) {
      value = value.split('T')[0]
    }
    const date = new Date(value.replace(/-/g, '/'))
    let day = date.getDate()
    let month = (date.getMonth() + 1)
    if (day < 10) {
      day = '0' + day
    }
    if (month < 10) {
      month = '0' + month
    }

    return day + '.' + month + '.' + date.getFullYear() + (time ? ' ' + value.split(' ')[1].substr(0, 5) : '')
  }
})

Vue.filter('formatAmount', function (value) {
  if (typeof value === 'number') { value = value.toString() }
  if (value) {
    if (value.toString().includes('.')) {
      /** округляем до сотых */
      value = parseFloat(value).toFixed(2).toString()
    }

    let result = ''
    const isFloat = value.indexOf('.')
    if (isFloat > 0) {
      result = ',' + value.substring(isFloat + 1)
      value = value.slice(0, isFloat)
    }

    if (value.length - 1 < 3) {
      return value + result
    }

    let index = value.length
    while (index > 0) {
      /** добавляем непереносимый пробел ($nbsp;) */
      result = '\u00A0' + value.substring(index - 3, index) + result
      index = index - 3
    }

    return result.trim()
  }
  return value
})
