import createApp from './app.js'

import messagingService from './service.messaging.js'

const appConfig = {"apiKey":"AIzaSyC8h8JK2oQoHiRdYjiaV94J1O5uJ8ZJ6KY","authDomain":"arthall-online.firebaseapp.com","databaseURL":"https:\u002F\u002Farthall-online.firebaseio.com","projectId":"arthall-online","storageBucket":"arthall-online.appspot.com","messagingSenderId":"984515715995","appId":"1:984515715995:web:9874bf23f35c9ddab9b73e","measurementId":"G-XF01MBD0ZS"}

export default async (ctx, inject) => {
  let firebase, session
  let firebaseReady = false

  const fire = {
    async appReady() {
      if (!firebaseReady) {
        ({ firebase, session } = await createApp(appConfig, ctx))
        firebaseReady = true;
      }
      return session
    },
    async ready() {
      await fire.appReady()

      let servicePromises = []

      if (process.client) {
        servicePromises = [
        fire.messagingReady(),

        ]
      }

      await Promise.all(servicePromises)
      return session
    }
  }

  if (process.server) {
  }

  if (process.client) {
    fire.messaging = null
    fire.messagingReady = async () => {
      if (!fire.messaging) {
        await fire.appReady()
        fire.messaging = await messagingService(session, firebase, ctx, inject)
      }

      return fire.messaging
    }
  }

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}