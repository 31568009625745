export default (ctx, inject) => {
  const options = JSON.parse (`{"id":"","url":"","apns":false}`)

  function checkPermission () {
    if (process.server) {
      return 'default'
    }

    if ('safari' in window) {
      // @ts-ignore
      const permissionData = window.safari.pushNotification.permission(options.id)
      return permissionData.permission
    } else {
      return 'Notification' in window && Notification.permission
    }
  }

  function getBrowser () {
    const ua = navigator.userAgent.toLowerCase()

    if (ua.includes('edg/')) {
      return 'edge'
    } else if (ua.includes('safari') && !ua.includes('chrome')) {
      return 'safari'
    } else {
      return 'chrome'
    }
  }

  function saveData (data) {
    return ctx.app.$axios.patch(process.env.API_ENDPOINT + 'user', data)
  }

  function getPermission () {
    let promise

    if ('safari' in window) {
      promise = new Promise((resolve) => {
        // @ts-ignore
        window.safari.pushNotification.requestPermission(
          options.url,
          options.id,
          {},
          resolve
        )
      }).then((permissionData) => {
        if (permissionData.permission !== 'granted') {
          throw new Error('No granted: ' + permissionData.permission)
        }

        return {
          type: 'apns',
          web_push_token: permissionData.deviceToken
        }
      })
    } else {
      promise = ctx.app.$fire.messaging.requestPermission().then(async () => {
        let token = await ctx.app.$fire.messaging.getToken()
        return {
          type: 'firebase',
          web_push_token: token
        }
      })
    }

    return promise
  }

  function getId () {
    return options.id
  }

  function getUrl () {
    return options.url
  }

  inject('webPush', {
    checkPermission,
    getBrowser,
    getPermission,
    saveData,
    getId,
    getUrl
  })
}
