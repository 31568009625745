import { render, staticRenderFns } from "./LayoutHeader.vue?vue&type=template&id=45c57ed3&scoped=true&"
import script from "./LayoutHeader.vue?vue&type=script&lang=ts&"
export * from "./LayoutHeader.vue?vue&type=script&lang=ts&"
import style0 from "./LayoutHeader.vue?vue&type=style&index=0&id=45c57ed3&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c57ed3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutMenu: require('/var/www/arthall/arthall.front/components/layout/LayoutMenu.vue').default})
