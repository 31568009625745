import { cacheAdapterEnhancer } from 'axios-extensions'
import LRUCache from 'lru-cache'
const ONE_DAY = 1000 * 60 * 60 * 24

const defaultCache = new LRUCache({ maxAge: ONE_DAY })

/**
 * axios plugin
 *
 * @param {*} root0 - app context
 * @param {*} root0.$axios - axios
 */
export default function ({ $axios }) {
  const defaults = $axios.defaults
  defaults.adapter = cacheAdapterEnhancer(defaults.adapter, {
    enabledByDefault: false,
    defaultCache
  })

  $axios.onRequest((config) => {
    config.requireToken = config.requireToken === undefined || config.requireToken
    const originalRequest = config

    const issueToken = function () {
      let isLocalStorageAccess = false
      try {
        localStorage.getItem('storedToken')
        isLocalStorageAccess = true
      } catch (e) {}
      return new Promise((resolve) => {
        if (originalRequest.headers.Authorization) {
          const authToken = originalRequest.headers.Authorization.split(' ')[1]
          try {
            localStorage.setItem('storedToken', authToken)
          } catch (e) {}
          resolve(authToken)
        } else if ((!isLocalStorageAccess || localStorage.getItem('storedToken') === null ||
          localStorage.getItem('storedToken') === '') && config.requireToken) {
          return $axios.post(process.env.API_ENDPOINT + '/api/v3/user',
            require('qs').stringify({
              device_name: 'browser'
            }), {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then((response) => {
            const authToken = response.data.token
            if (isLocalStorageAccess) {
              try {
                localStorage.setItem('storedToken', authToken)
              } catch (e) {}
            }
            resolve(authToken)
          })
        } else if (isLocalStorageAccess) {
          resolve(localStorage.getItem('storedToken'))
        } else {
          resolve()
        }
      })
    }

    const isCreateUser = function (config) {
      if (config.url === process.env.API_ENDPOINT + '/api/v3/user') {
        return config.method === 'POST' || config.method === 'post'
      } else {
        return false
      }
    }

    if (!isCreateUser(config)) {
      return issueToken().then((token) => {
        /** Для списка картин на главной. Если токен есть, то отправить на другой URL */
        if (/\/web-list(?:\?|$)/.test(originalRequest.url) && token) {
          originalRequest.url = process.env.API_ENDPOINT + '/api/v3/paintings/list'
          originalRequest.cache = false
        }

        if (token) {
          originalRequest.headers.Authorization = 'Bearer ' + token
        }
        return Promise.resolve(originalRequest)
      })
    }

    return config
  })
}
