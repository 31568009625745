export const getBreadCrumbs = (path: string, idTitles: string[] = []) => {
  if (path.endsWith('/')) { path = path.slice(0, -1) }
  const pathsArr = path.split('/')
  if (pathsArr.length < 2) { return [] }

  let currentHref = window.location.origin

  return pathsArr
    .map((pathEl, index) => {
      console.log(pathEl)
      currentHref += `${pathEl}/`

      return {
        title: index !== 0 && Number.isInteger(+pathEl) && idTitles.length > 0 ? idTitles.shift() : window.$nuxt.$t(`breadcrumbs.${pathEl.length > 0 ? pathEl : 'index'}`),
        href: currentHref
      }
    })
}
