
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  langList: any = [
    { code: 'ru', title: 'Русский' },
    { code: 'en', title: 'English' }
  ]
}
