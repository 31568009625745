
import { Component, Vue } from 'nuxt-property-decorator'
import AmpMenu from '~/components/amp/layout/AmpMenu.vue'

@Component({
  components: { AmpMenu }
})
export default class extends Vue {
  name: 'LayoutHeader'
}
