import { ref } from 'vue'

let init

export const useBreadcrumbsStore = () => {
  if (init) { return init };

  console.log(123)

  const store = ref({
    titles: [],
    setTitles (val) {
      this.titles = val
    },
    resetTitles () {
      this.titles = []
    }
  })

  init = store
  return store
}
