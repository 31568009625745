
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop() error!: any

  head () {
    return {
      title: this.$t('head.title.error') + ' ' + this.error.statusCode,
      meta: [
        { hid: 'og:title', name: 'og:title', content: this.$t('head.title.buy') }
      ]
    }
  }
}
