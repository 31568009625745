
import { Component, Vue } from 'nuxt-property-decorator'
import AmpHeader from '~/components/amp/layout/AmpHeader.vue'
import MenuContent from '~/components/layout/menu/MenuContent.vue'
import MenuBanner from '~/components/layout/menu/MenuBanner.vue'

@Component({
  components: { MenuBanner, MenuContent, AmpHeader }
})
export default class extends Vue {
  showMenu: boolean = false

  get isIOSDevice () {
    return process.client && ([
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document))
  }
}
