
import { Component, Prop, Ref, Vue, Watch } from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Ref('rating') rating!: HTMLDivElement

  @Prop() rate!: number
  @Prop() userCurrentRate!: number | null
  @Prop() paintId!: number

  mouseActive (index: number, className: string = 'mod-enter') {
    for (let i = 0; i < 5; i++) {
      if (i >= index) {
        this.rating.children[i].classList.remove(className)
        continue
      }

      if (!this.rating.children[i].classList.contains(className)) {
        this.rating.children[i].classList.add(className)
      }
    }

    if (className === 'mod-selected') {
      this.$eventBus.$emit('updateRate', {
        id: this.paintId,
        rate: index
      })
      this.$axios.patch(process.env.API_ENDPOINT + '/api/v3/paintings/' + this.paintId + '/set-rate', {
        rate: index
      })
    }
  }

  @Watch('userCurrentRate')
  paintOverRateStar () {
    if (this.userCurrentRate) {
      for (let i = 0; i < 5; i++) {
        this.rating.children[i].classList.add('mod-selected')
        if (i >= this.userCurrentRate) {
          this.rating.children[i].classList.remove('mod-selected')
        }
      }
    }
  }

  mounted () {
    this.paintOverRateStar()
  }
}
