
import { Component, Vue } from 'nuxt-property-decorator'
import ToggleLang from '~/components/layout/menu/ToggleLang.vue'

@Component({
  components: { ToggleLang }
})
export default class extends Vue {
  showLangList: boolean = false
}
