
import { Component, Vue } from 'nuxt-property-decorator'
import LayoutMenuBanner from '~/components/layout/menu/MenuBanner.vue'
import MenuContent from '~/components/layout/menu/MenuContent.vue'

@Component({
  components: { MenuContent, LayoutMenuBanner }
})
export default class extends Vue {
  showMenu: boolean = false
}
