import { debounce } from 'lodash'
import { ref } from 'vue'
let init

export const useWindowHandlers = () => {
  if (init) { return init }

  const handleResize = (ev) => {
    windowHandlers.value.resize.forEach(
      (fnc) => {
        fnc(ev)
      }
    )
  }

  window.addEventListener('resize', debounce(handleResize, 10))

  const windowHandlers = ref({
    nextId: 0,
    resize: [],

    listen (type, fnc, launchOnAdd = false, objKey = null) {
      if (!(type in this)) { return }
      if (Array.isArray(this[type])) { this[type].push(fnc) } else if (objKey) { this[type][objKey] ? this[type][objKey].push(fnc) : this[type][objKey] = [fnc] } else {
        console.log(fnc, 'not added')
      }

      if (launchOnAdd) { fnc() }
    },
    stopListening (type, fnc, objKey = null) {
      if (!(type in this)) { return }

      if (Array.isArray(this[type])) {
        const index = this[type].findIndex(fncEl => fncEl === fnc)
        if (index === -1) { return }

        this[type].splice(index, 1)
      } else if (objKey) {
        const index = this[type][objKey].findIndex(fncEl => fncEl === fnc)
        if (index === -1) { return }

        this[type].splice(index, 1)
      }
    }

  })

  init = windowHandlers.value

  return windowHandlers.value
}
