
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { email, required, numeric } from 'vuelidate/lib/validators'

enum Status {
  INFO = 'INFO',
  ORDER = 'ORDER',
  COMPLETE = 'COMPLETE'
}

@Component({
  validations () {
    return {
      formData: {
        name: { required },
        phone: { required, numeric },
        email: { email, required }
      }
    }
  }
})
export default class SaleInfo extends Vue {
  @Prop() paintingInfo: any
  showInfo = true
  currentStatus: Status = Status.INFO
  showInterior = false
  errMessage = ''
  formData = {
    id: '',
    name: '',
    phone: '',
    email: ''
  }

  artistDescription = ''

  additionalPaintings: any[] = []

  goBack () {
    this.currentStatus = Status.INFO
  }

  lookClick () {
    return true
  }

  get widthPaint () {
    return 120 / (200 / this.paintingInfo.sale_width_cm)
  }

  get heightPaint () {
    return 120 / (200 / this.paintingInfo.sale_height_cm)
  }

  orderClick () {
    this.currentStatus = Status.ORDER
  }

  buyClick () {
    this.errMessage = ''

    this.$v.$touch()
    if (this.$v.$error) {
      return
    }

    this.$axios.post(
      process.env.API_ENDPOINT + '/api/v3/paintings/send-buy-request', this.formData
    ).then(() => {
      this.currentStatus = Status.COMPLETE
    }).catch((err) => {
      this.errMessage = err
    })
  }

  openArtistPage () {
    this.$eventBus.$emit('HIDE_FULLSCREEN')
    return this.$router.push(`/artists/${this.paintingInfo.artist.url || this.paintingInfo.artist.id}`)
  }

  mounted () {
    this.formData.id = this.paintingInfo.id
    this.$axios.get(
      process.env.API_ENDPOINT + '/api/v3/paintings/' + this.paintingInfo.id + '/get-sale-info',
      {
        // @ts-ignore
        requireToken: true
      }
    ).then((response) => {
      this.artistDescription = response.data.artist
    })
    this.$axios.get(
      process.env.API_ENDPOINT + '/api/v3/artists/' + this.paintingInfo.artist_id,
      {
        // @ts-ignore
        requireToken: false,
        // @ts-ignore
        cache: true
      }
    ).then((response) => {
      this.additionalPaintings = response.data.paintings.slice(0, 4)
    })
  }
}
