import { render, staticRenderFns } from "./default.vue?vue&type=template&id=75f0dbd0&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=75f0dbd0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75f0dbd0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeader: require('/var/www/arthall/arthall.front/components/layout/LayoutHeader.vue').default,LayoutBreadcrumbs: require('/var/www/arthall/arthall.front/components/layout/LayoutBreadcrumbs.vue').default,LayoutFooter: require('/var/www/arthall/arthall.front/components/layout/LayoutFooter.vue').default})
