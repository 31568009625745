import { ref, watch } from 'vue'
let init
export const useModalStore = () => {
  if (init) { return init }

  const store = ref({
    modals: [],
    addModal () {
      this.modals.push(1)
    },
    removeModal () {
      this.modals.pop()
    }
  })

  watch(() => store.value.modals.length, (val) => {
    document.body.style = `overflow: ${val > 0 ? 'hidden' : 'auto'}`
  })

  init = store.value
  return store.value
}
